<template>
<div class='container'>
  <div class='frame'>
    <div class='bar rounded-lg mx-3'>
      <!-- <v-icon v-show="$route.name != 'Dashboard'">mdi-arrow-left</v-icon> -->
      <a @click="ChangeURL('')" class="btnbtm btnbottom1">
        <div class='iconbtm'>
            
          <i>
            <v-icon class='iconbtm'>mdi-home</v-icon>
          </i>
        </div>
      </a>
      <a @click="ChangeURL('History')" class="btnbtm">
        <div class='iconbtm'>
          <v-badge v-show="badgehistory" style="position:fixed;margin-left:25px;margin-top:5px" dot color="green"/>
          <i>
            <v-icon class='iconbtm'>mdi-history</v-icon>
          </i>
        </div>
      </a>
      <a @click="ChangeURL('CameraQR')" class="btnbtm">
        <div class='iconbtm'>
          <i>
            <v-icon class='iconbtm'>mdi-qrcode-scan</v-icon>
          </i>
        </div>
      </a>
      <a @click="ChangeURL('Log')" class="btnbtm">
        <div class='iconbtm'>
          <i>
            <v-icon class='iconbtm'>mdi-account-clock</v-icon>
          </i>
        </div>
      </a>

      <a @click="ChangeURL('auth')" class="btnbtm">
        <div class='iconbtm'>
          <i>
            <v-icon class='iconbtm'>mdi-logout</v-icon>
          </i>
        </div>
      </a>

    </div>
  </div>
</div>

  <!-- <v-bottom-navigation
    elevation="6"
    v-model="value"
    :input-value="focus"
    shift
    color="red darken-4"
  >
  <v-btn value="/" @click="ChangeURL('')">
      <span>Home</span>

      <v-icon>mdi-home</v-icon>
    </v-btn>
    <v-badge v-show="badgehistory" style="position:fixed;margin-right:140px;margin-top:10px" dot color="green"/>
    <v-btn value="history" @click="ChangeURL('History')">
      <span>History</span>
      <v-icon>mdi-history</v-icon>
    </v-btn>

    <v-btn value="camera" @click="ChangeURL('CameraQR')">
      <span>QR</span>
      <v-icon id="qrcode" class="mt-n3" x-large>mdi-qrcode-scan</v-icon>
    </v-btn>
    

    <v-btn value="pengaturan" @click="ChangeURL('Log')">
      <span>LOG</span>
      <v-icon>mdi-account-clock</v-icon>
    </v-btn>
    <v-btn value="logout" @click="ChangeURL('auth')">
      <span class="mr-4">Log Out</span>
      <v-icon class="mr-4">mdi-logout</v-icon>
    </v-btn>
  </v-bottom-navigation> -->
</template>
<script>
  import API from '@/services/http'
export default {
  data(){
      return{
          focus:true,
          value:'/',
          badgehistory:false,
          user:JSON.parse(localStorage.getItem('logged'))
      }
  },
  mounted(){
      console.log(window.innerWidth)
      console.log(this.$route)
      this.cekhistory()
  },
  methods:{
    LogOut(){
      // console.log('ok')
      this.$LogOutMobile()
      // localStorage.removeItem('logged')
      // this.$router.push('/auth')
    },
    cekhistory(){
    API.get('seenhistory/'+this.user.id_user).then(x=>{
      if(x.data.seen == 'N'){
        this.badgehistory = false
      }
      if(x.data.seen == 'Y'){
        this.badgehistory = true
      }
    })
  },
    ChangeURL(x){
      if(x == 'auth'){
        return this.LogOut()
      }else{
        this.cekhistory()
        this.$ChangeURL(x)
      }
    }, 
  }
}
</script>
<style lang="scss">

// Sizes
$frameWidthMobile: 340px;
$iconSize: 33px;

.bar {
  width: $frameWidthMobile;
  height: 70px;
  background: #fff;
  box-shadow: 0 10px 40px rgba(51,51,51,.4);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 15px;
}
.barhide {
  width: $frameWidthMobile;
  height: 70px;
  background: #fff;
  box-shadow: 0 10px 40px rgba(51,51,51,.4);
  // display: flex;
  // align-items: center;
  // flex-wrap: nowrap;
  // justify-content: space-between;
  flex-direction: row;
  // padding: 0 15px;
}

.btnbtm{
  text-decoration: none;
  padding: 0px 5px 0px 5px;
  overflow: hidden;
  &:focus{
    background-color: #43658B;
    margin-top: 5px;
    text-decoration: none;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    .iconbtm{
      color:white;
    }
  }
  &:active{
    background-color: #43658B;
    height: 30px;
    border-radius:15%;
    -moz-border-radius:15%;
    -webkit-border-radius:15%;
    text-decoration: none;
    .iconbtm{
      color:white;
    }
  }
}

// Icons
// .els-wrap {
//  display: flex;
// }
// .els-wrap {
//   display: flex;
//   align-items: center;
//   width: $iconSize;
//   background-size: 1200px;
//   background-position: 100% 50%;
//   color: $iconColor;
//   padding: 0 9px;
//   border-radius: 100px;
//   overflow: hidden;
//   text-decoration: none;
//   cursor: pointer;
//   transition: width 200ms, padding 200ms, border-radius 300ms, background-position 900ms;
//   transition-timing-function: ease-in-out;
//   &:hover {
//     // width: $iconSize *2.5; 
//     // background-position: 0 50%; 
//     // padding: 3px 13px;
//     border-radius: 50px; 
//     .icon{
//       font-size: $iconSize - 6; 
//       // margin-right: 10px;
//     }
//     .label {
//       opacity: 1; 
//     }
//   }
//   &:focus {
    // // width: $iconSize *2.5; 
    // // background-position: 0 50%; 
    // // padding: 3px 13px;
    // border-radius: 50px; 
    // .icon{
    //   font-size: $iconSize - 6; 
    //   // margin-right: 10px;
    // }
//     .label {
//       opacity: 1; 
//     }
//   }

//   .icon{
//     font-size: $iconSize;
//     // margin-right: 25px;
//     position: relative;
//     z-index: 2;
//     transition: font-size 250ms; 
//     transition-timing-function: ease-out;
//   }
//   .label {
//     font-weight: 600;
//     letter-spacing: .15em;
//     opacity: 0;
//     position: relative;
//     z-index: 2;
//     font-size: $iconSize/3;
//     transition: opacity 400ms;
//     transition-delay: 130ms;
//     transition-timing-function: ease-out;
//   }
// }



</style>
